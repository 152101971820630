<template>
    <div class="customer-box">
        <div class="search-box">
            <div>
                <div class="search-one">
                    <p class="left">
                        <Department
                            ref="department"
                            :departmentWidth="146"
                            :adminWidth="146"
                            :depSearch="btnP.depSearch"
                            :searchUser="btnP.userSearch"
                            @searchData="searchData"
                        ></Department>
                        <!-- <span>年份</span>
                        <el-date-picker
                            v-model="searchVal.year"
                            type="year"
                            size="small"
                            style="width: 146px"
                            value-format="yyyy"
                            :clearable="false"
                            placeholder="选择年"
                            @change="changeYear"
                        >
                        </el-date-picker> -->
                        <span>月份</span>
                        <el-date-picker
                            v-model="searchVal.month"
                            type="month"
                            size="small"
                            style="width: 146px"
                            value-format="yyyy-MM"
                            format="yyyy-MM"
                            :clearable="false"
                            placeholder="选择月"
                            @change="changeMonth"
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <el-button
                            size="mini"
                            type="primary"
                            style="
                                background: #fff;
                                color: #2370eb;
                                border-color: #2370eb;
                                margin-right: 12px;
                            "
                            @click="onPerformanceDetail"
                            >查看详情</el-button
                        > 
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title_all">
            <div class="table_title_left">
                <p>
                    合同统计
                </p>
                <div class="table_title_left_box">
                    <div class="table_title_left_item">
                        <i class="iconfont icon-yeji1"></i>
                        <div>
                            <span
                                >新增合同</span
                            >
                            <p>
                                ￥{{
                                    $util.formatWithArray(performanceContractData &&
                                    performanceContractData.addContractAmount
                                        ? performanceContractData.addContractAmount
                                        : 0 || 0)
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="table_title_left_item">
                        <div>
                            <div>
                                <span>待回款金额</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.todoPaymentReceivedAmount
                                            ? performanceContractData.todoPaymentReceivedAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        </br>
                        <div>
                            <div>
                                <span>已到账金额</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.paymentReceivedAmount
                                            ? performanceContractData.paymentReceivedAmount 
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="table_title_left_item2">
                        <div>
                            <i class="iconfont icon-yejiguanli1"></i>
                            <div>
                                <span>增量回款</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.addContractReturnAmount
                                            ? performanceContractData.addContractReturnAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-xiaoshoujia"></i>
                            <div>
                                <span>增量业绩</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.addPerformanceAmount
                                            ? performanceContractData.addPerformanceAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-dingjiaziyuanwei"></i>
                            <div>
                                <span>存量回款</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.stockContractReturnAmount
                                            ? performanceContractData.stockContractReturnAmount 
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-dingdan4"></i>
                            <div>
                                <span
                                    >存量业绩</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceContractData &&
                                        performanceContractData.stockPerformanceAmount
                                            ? performanceContractData.stockPerformanceAmount 
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-title_all">
            <div class="table_title_left">
                <p>
                    业绩统计
                </p>
                <div style="
                        width: 100%;
                        height: calc(100% - 59px);
                        position: relative;
                    "
                >
                    <swiper :options="mySwiperOption" id="swiper" ref="swiper">
                        <swiper-slide>
                            <div class="product_box">
                                <div>
                                    <p>
                                        <span
                                            >腾讯企业邮箱
                                            <i
                                                :class="'iconfont icon-youxiang3'"
                                            ></i>
                                        </span>
                                        <span>增量业绩 <br /><em> ￥ 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.tencentAddPerformanceAmount
                                                ? performanceProductData.tencentAddPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                        <span>存量业绩 <br /><em> ￥ 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.tencentStockPerformanceAmount
                                                ? performanceProductData.tencentStockPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span
                                            >阿里企业邮箱
                                            <i
                                                :class="'iconfont icon-youxiang3'"
                                            ></i>
                                        </span>
                                        <span>增量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.aliAddPerformanceAmount
                                                ? performanceProductData.aliAddPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                        <span>存量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.aliStockPerformanceAmount
                                                ? performanceProductData.aliStockPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span
                                            >网易企业邮箱
                                            <i
                                                :class="'iconfont icon-youxiang3'"
                                            ></i>
                                        </span>
                                        <span>增量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.neteaseAddPerformanceAmount
                                                ? performanceProductData.neteaseAddPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                        <span>存量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.neteaseStockPerformanceAmount
                                                ? performanceProductData.neteaseStockPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span
                                            >域名
                                            <i
                                                :class="'iconfont icon-yumingfuwu'"
                                            ></i>
                                        </span>
                                        <span>增量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.domainAddPerformanceAmount
                                                ? performanceProductData.domainAddPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                        <span>存量业绩 <br /><em> 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.domainStockPerformanceAmount
                                                ? performanceProductData.domainStockPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span
                                            >其他产品
                                            <i
                                                :class="'iconfont  icon-fenleiorguangchangorqitatianchong'"
                                            ></i>
                                        </span>
                                        <span>增量业绩 <br /><em> ￥ 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.otherAddPerformanceAmount
                                                ? performanceProductData.otherAddPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                        <span>存量业绩 <br /><em> ￥ 
                                            {{
                                            $util.formatWithArray(performanceProductData &&
                                            performanceProductData.otherStockPerformanceAmount
                                                ? performanceProductData.otherStockPerformanceAmount 
                                                : 0 || 0)
                                            }}
                                        </em></span>
                                    </p>
                                </div>
                            </div>
                            
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>

        <div class="table-title_all">
            <div class="table_title_left">
                <p>
                    指标统计
                </p>
                <div class="table_title_left_box">
                    <div class="table_title_left_item">
                        <i class="iconfont icon-yeji1"></i>
                        <div>
                            <span
                                >月度完成率</span
                            >
                            <p>
                                {{
                                    monthComplete(performanceQuotaData)
                                }}%
                            </p>
                        </div>
                    </div>
                    <div class="table_title_left_item">
                        <img
                            src="@/img/yuangongyeji.png"
                            style="margin-bottom: 16px"
                            alt=""
                        />
                        <div>
                            <span>累计完成率</span>
                            <p>
                                {{
                                    yearComplete(performanceQuotaData)
                                }}%
                            </p>
                        </div>
                    </div>
                    <div class="table_title_left_item">
                        <img
                            src="@/img/yuangongyeji.png"
                            style="margin-bottom: 16px"
                            alt=""
                        />
                        <div>
                            <span>业绩差</span>
                            <p>
                                {{
                                    $util.formatWithArray(performanceDiffer(performanceQuotaData))
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="table_title_left_item2" v-if="btnP.userSearch">
                        <div>
                            <i class="iconfont icon-yejiguanli1"></i>
                            <div>
                                <span>部门年度指标</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.yearDepartmentQuotaAmount
                                            ? performanceQuotaData.yearDepartmentQuotaAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-xiaoshoujia"></i>
                            <div>
                                <span>部门月度指标</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.monthDepartmentQuotaAmount
                                            ? performanceQuotaData.monthDepartmentQuotaAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-dingjiaziyuanwei"></i>
                            <div>
                                <span>部门年度业绩</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.yearDepartmentPerformanceAmount
                                            ? performanceQuotaData.yearDepartmentPerformanceAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-dingdan4"></i>
                            <div>
                                <span
                                    >部门月度业绩</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.monthDepartmentPerformanceAmount
                                            ? performanceQuotaData.monthDepartmentPerformanceAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="table_title_left_item2">
                        <div>
                            <i class="iconfont icon-yejiguanli1"></i>
                            <div>
                                <span>个人年度指标</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.yearAdminQuotaAmount
                                            ? performanceQuotaData.yearAdminQuotaAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-xiaoshoujia"></i>
                            <div>
                                <span>个人月度指标</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.monthAdminQuotaAmount
                                            ? performanceQuotaData.monthAdminQuotaAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div
                            :class="userType == 19 ? 'shangwu' : ''"
                            @click="toConlist"
                        >
                            <i class="iconfont icon-dingjiaziyuanwei"></i>
                            <div>
                                <span>个人年度业绩</span>
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.yearAdminPerformanceAmount
                                            ? performanceQuotaData.yearAdminPerformanceAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i class="iconfont icon-dingdan4"></i>
                            <div>
                                <span
                                    >个人月度业绩</span
                                >
                                <p>
                                    ￥{{
                                        $util.formatWithArray(performanceQuotaData &&
                                        performanceQuotaData.monthAdminPerformanceAmount
                                            ? performanceQuotaData.monthAdminPerformanceAmount
                                            : 0 || 0)
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div class="table_title">
            <p>
                {{
                    !btnP.userSearch && !btnP.depSearch
                        ? '我的'
                        : btnP.userSearch && !btnP.depSearch
                        ? '成员'
                        : '部门'
                }}回款进度
            </p>
        </div>
        <div class="table">
            <Table ref="table" @totalNub="totalNub" @getData="getData" />
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[3,10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import Table from '../table/table.vue';
import {
    performanceBriefGet,
    performanceTargetBriefGet,
    performanceBriefProductList,
    performanceContractBrief,
    performanceProductBrief,
    performanceQuotaBrief,
} from '@/api/performance/performance.js';
import { productList } from '@/api/product/product.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../../components/DepartmentCus';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
let elwementResizeDetectorMarker = require('element-resize-detector');
export default {
    components: {
        Table,
        swiper,
        swiperSlide,
        Department,
    },
    inject: ['reload'],
    data() {
        return {
            searchVal: {
                month:
                    new Date().getFullYear() +
                    '-' +
                    ((new Date().getMonth()  + 1) > 9 ? (new Date().getMonth()  + 1) : '0' + (new Date().getMonth()  + 1)),
                departmentId: '',
                adminId: '',
            },
            btnP: {},
            userName: [],
            dempData: [],
            cityList: [],
            primaryEmailBrandList: this.$searchForm.mailboxbrand(),
            pagesize: 3,
            total: 0,
            currentPage: 1,
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            drawerAdd: false,
            direction: 'rtl',
            isPage: true,
            userType: sessionStorage.getItem('userType'),
            active: '潜在客户',
            customerBrief: {},
            prevCustomerBrief: {},
            mySwiperOption: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                loop: false,
            },
            customerBriefOrderArray: [],
            productDataList: [],
            performanceBriefData: {},
            performanceTargetBriefData: {},
            performanceBriefDataList: [],
            performanceBriefProductDataList: [],
            departmentName: '',
            performanceContractData:{},
            performanceProductData:{},
            performanceQuotaData:{},
        };
    },
    created() {
        window.addEventListener('keydown', this.keyDown, true);
        // window.addEventListener('resize', this.resizeWindow, true);
        
    },
    mounted() {
        this.getBtn();
        let erd = elwementResizeDetectorMarker();
        let that = this;
        erd.listenTo(document.getElementById('swiper'), this.resizeWindow);
    },
    methods: {
        monthComplete(performanceQuotaData){
            if(this.btnP.userSearch){
                let monthTotalQuota = performanceQuotaData && performanceQuotaData.monthDepartmentQuotaAmount
                                            ? performanceQuotaData.monthDepartmentQuotaAmount
                                            : 0 || 0
                let monthTotalPerformance = performanceQuotaData && performanceQuotaData.monthDepartmentPerformanceAmount
                                            ? performanceQuotaData.monthDepartmentPerformanceAmount
                                            : 0 || 0
                if(monthTotalQuota == 0 || monthTotalPerformance == 0){
                    return 0;
                }else{
                    return ((monthTotalPerformance/monthTotalQuota)*100).toFixed(2);
                }                            
            }else{
                let monthTotalQuota = performanceQuotaData && performanceQuotaData.monthAdminQuotaAmount
                                            ? performanceQuotaData.monthAdminQuotaAmount
                                            : 0 || 0
                let monthTotalPerformance = performanceQuotaData && performanceQuotaData.monthAdminPerformanceAmount
                                            ? performanceQuotaData.monthAdminPerformanceAmount
                                            : 0 || 0
                if(monthTotalQuota == 0 || monthTotalPerformance == 0){
                    return 0;
                }else{
                    return ((monthTotalPerformance/monthTotalQuota)*100).toFixed(2);
                }                            
            }
        },
        yearComplete(performanceQuotaData){
            if(this.btnP.userSearch){
                let yearTotalQuota = performanceQuotaData && performanceQuotaData.yearNowDepartmentQuotaAmount
                                            ? performanceQuotaData.yearNowDepartmentQuotaAmount
                                            : 0 || 0
                let yearTotalPerformance = performanceQuotaData && performanceQuotaData.yearDepartmentPerformanceAmount
                                            ? performanceQuotaData.yearDepartmentPerformanceAmount
                                            : 0 || 0
                if(yearTotalQuota == 0 || yearTotalPerformance == 0){
                    return 0;
                }else{
                    return ((yearTotalPerformance/yearTotalQuota)*100).toFixed(2);
                }                            
            }else{
                let yearTotalQuota = performanceQuotaData && performanceQuotaData.yearNowAdminQuotaAmount
                                            ? performanceQuotaData.yearNowAdminQuotaAmount
                                            : 0 || 0
                let yearTotalPerformance = performanceQuotaData && performanceQuotaData.yearAdminPerformanceAmount
                                            ? performanceQuotaData.yearAdminPerformanceAmount
                                            : 0 || 0
                if(yearTotalQuota == 0 || yearTotalPerformance == 0){
                    return 0;
                }else{
                    return ((yearTotalPerformance/yearTotalQuota)*100).toFixed(2);
                }                           
            }
        },
        performanceDiffer(performanceQuotaData){
            if(this.btnP.userSearch){
                let yearTotalQuota = performanceQuotaData && performanceQuotaData.yearNowDepartmentQuotaAmount
                                            ? performanceQuotaData.yearNowDepartmentQuotaAmount
                                            : 0 || 0
                let yearTotalPerformance = performanceQuotaData && performanceQuotaData.yearDepartmentPerformanceAmount
                                            ? performanceQuotaData.yearDepartmentPerformanceAmount
                                            : 0 || 0
                
                return (yearTotalPerformance - yearTotalQuota).toFixed(2);
                                       
            }else{
                let yearTotalQuota = performanceQuotaData && performanceQuotaData.yearNowAdminQuotaAmount
                                            ? performanceQuotaData.yearNowAdminQuotaAmount
                                            : 0 || 0
                let yearTotalPerformance = performanceQuotaData && performanceQuotaData.yearAdminPerformanceAmount
                                            ? performanceQuotaData.yearAdminPerformanceAmount
                                            : 0 || 0
                
                return (yearTotalPerformance - yearTotalQuota).toFixed(2);
            }
        },
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            console.log(btn.userSearch && !btn.depSearch);
            if (btn.depSearch) {
                this.getDempData();
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (!btn.depSearch && btn.userSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
                this.$refs.department.adminId = Number(
                    sessionStorage.getItem('adminId')
                );
                this.searchVal.adminId = Number(
                    sessionStorage.getItem('adminId')
                );
            }
            this.departmentName = sessionStorage.getItem('departmentName');
            //this.getData();
            // this.performanceBriefGet();
            // this.performanceTargetBriefGet();
            // this.productList();
            this.changeMonth();
        },
        performanceBriefGet() {
            let data = {
                param: {},
            };
            // if (!this.btnP.depSearch) {
            //     data.param.departmentId =
            //         sessionStorage.getItem('departmentId');
            // } else {
            //     if (this.searchVal.departmentId) {
            //         data.param.departmentId = this.searchVal.departmentId;
            //     }
            // }
            if (this.btnP.userSearch) {
                data.param.adminId = this.searchVal.adminId;
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceBriefGet(data).then((res) => {
                console.log(res);
                this.performanceBriefData = res.data;
            });
        },
        performanceTargetBriefGet() {
            let data = {
                param: {},
            };
            if (this.btnP.userSearch) {
                data.param.adminId = this.searchVal.adminId;
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceTargetBriefGet(data).then((res) => {
                this.performanceTargetBriefData = res.data;
            });
        },

        resizeWindow(element) {
            let width = this.$refs.swiper.swiper.width;
            if (width <= 540) {
                this.customerBriefOrderArray = this.slickArrayFn(
                    this.performanceBriefProductDataList,
                    2
                );
            } else if (width <= 712) {
                this.customerBriefOrderArray = this.slickArrayFn(
                    this.performanceBriefProductDataList,
                    3
                );
            } else if (width <= 880) {
                this.customerBriefOrderArray = this.slickArrayFn(
                    this.performanceBriefProductDataList,
                    4
                );
            } else {
                this.customerBriefOrderArray = this.slickArrayFn(
                    this.performanceBriefProductDataList,
                    5
                );
            }
            this.customerBriefOrderArray = JSON.parse(
                JSON.stringify(this.customerBriefOrderArray)
            );
        },
        productList() {
            let data = {
                param: {
                    status: 1,
                    type: 2,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data).then((res) => {
                this.productDataList = res.data.list;

                this.performanceBriefProductList();
            });
        },
        performanceBriefProductList() {
            let data = {
                param: {},
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (this.btnP.userSearch) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceBriefProductList(data).then((res) => {
                res.data.forEach((item) => {
                    item.productName = this.productDataList.find(
                        (itm) => item.productCategory == itm.id
                    ).productName;
                    item.icon =
                        this.productDataList
                            .find((itm) => item.productCategory == itm.id)
                            .productName.indexOf('邮箱') != -1
                            ? 'icon-youxiang3'
                            : this.productDataList
                                  .find((itm) => item.productCategory == itm.id)
                                  .productName.indexOf('域名') != -1
                            ? 'icon-yumingfuwu'
                            : 'icon-fenleiorguangchangorqitatianchong';
                });
                this.performanceBriefProductDataList = res.data;
                this.resizeWindow();
            });
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                // if (this.searchVal.departmentId == '') {
                //     this.searchVal.departmentId = Number(
                //         sessionStorage.getItem('departmentId')
                //     );
                // }
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 3;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            if (data.departmentId) {
                this.departmentName = this.dempData.find((item) => {
                    return item.id == data.departmentId;
                }).departmentName;
            }

            this.getData();
            // this.performanceBriefGet();
            // this.performanceTargetBriefGet();
            // this.performanceBriefProductList();
            this.performanceContractBrief();
            this.performanceProductBrief();
            this.performanceQuotaBrief();
        },
        performanceContractBrief(){
            let data = {
                param: {},
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if(!data.param.adminId){
                data.param.adminType = 1;
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceContractBrief(data).then((res) => {
                this.performanceContractData = res.data;
            });
        },
        performanceProductBrief(){
            let data = {
                param: {},
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if(!data.param.adminId){
                data.param.adminType = 1;
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceProductBrief(data).then((res) => {
                this.performanceProductData = res.data;
            });
        },
        performanceQuotaBrief(){
            let data = {
                param: {},
            };
            data.param.departmentId =
                sessionStorage.getItem('departmentId');
            if (this.searchVal.departmentId) {
                data.param.departmentId = this.searchVal.departmentId;
            }
            data.param.adminId = sessionStorage.getItem('adminId');
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            performanceQuotaBrief(data).then((res) => {
                this.performanceQuotaData = res.data;
            });
           
        },
        getData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (this.searchVal.departmentId) {
                    data.param.departmentId = this.searchVal.departmentId;
                }
            }
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (this.searchVal.month) {
                data.param.createMonth = this.searchVal.month;
            }
            if (
                (!this.btnP.depSearch &&
                    this.btnP.userSearch &&
                    !this.searchVal.adminId) ||
                (this.btnP.depSearch &&
                    this.btnP.userSearch &&
                    this.searchVal.departmentId)
            ) {
                data.param.paymentGroupType = 1;
            }
            if (
                this.btnP.depSearch &&
                this.btnP.userSearch &&
                !this.searchVal.departmentId
            ) {
                data.param.paymentGroupType = 2;
            }

            this.$refs.table.getData(data, this.btnP);
        },
        totalNub(i) {
            this.total = i;
        },
        // 时间输入框变化
        expireTime() {
            this.searchVal.name = '';
            this.currentPage = 1;
            this.pagesize = 3;
            this.getData();
        },
        getTableData2(active) {
            this.active = active;
            this.currentPage = 1;
            this.pagesize = 3;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },

        handleClose() {
            this.dialogChoice = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {
                cityId: '',
            };
            this.getData();
            this.$searchLabel.commit('emptyData');
        },
        getCityId(val) {
            this.searchVal.cityId = '';
            if (val) {
                this.cityList = this.$address.cascader().find((item) => {
                    return item.value == val;
                }).children;
            }
            console.log(this.cityList);
            this.pagesize = 3;
            this.currentPage = 1;
            this.getData();
        },
        slickArrayFn(array, sizeNum) {
            var result = [];
            var size = sizeNum;
            for (var i = 0; i < Math.ceil(array.length / size); i++) {
                var start = i * size;
                var end = start + size;
                result.push(array.slice(start, end));
            }
            return result;
        },
        onClose() {
            this.$router.go(-1);
        },
        onPerformanceDetail(){
            this.$router.push({
                path: '/myachievementlistdetails',
                query: {
                    adminId: this.searchVal.adminId,
                    departmentId: this.searchVal.departmentId,
                    month: this.searchVal.month,
                },
            });
        },

        changeMonth(val) {
            // this.performanceBriefGet();
            // this.performanceTargetBriefGet();
            this.getData();
            // this.performanceBriefProductList();
            this.performanceContractBrief();
            this.performanceProductBrief();
            this.performanceQuotaBrief();
        },
        toConlist() {
            if (this.userType == 19) {
                sessionStorage.setItem('isAchievement', 1);
                this.$router.push({
                    path: '/conlist',
                });
            }
        },
    },
    destroyed() {
        this.$searchLabel.commit('emptyData');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.search-box {
    width: 100%;
    display: flex;
    > div:first-child {
        flex: 1;
        background: #fff;
        border-radius: 4px;
    }
    .add_cus_num {
        width: 300px;
        margin-left: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        i {
            display: block;
            width: 64px;
            height: 64px;
            background: rgba(253, 157, 56, 0.21);
            border-radius: 32px;
            text-align: center;
            line-height: 64px;
            font-size: 30px;
            color: #ffa802;
            margin-left: 16px;
        }
        p {
            display: flex;
            padding-left: 13px;
            flex-direction: column;
            flex: 1;
            span {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
            span:nth-child(2) {
                font-size: 26px;
                margin-top: 6px;
            }
        }
        button {
            font-size: 12px;
            width: 56px;
            height: 20px;
            border-radius: 13px;
            border: 1px solid #2370eb;
            background-color: #fff;
            cursor: pointer;
            margin-right: 15px;
            color: #2370eb;
            margin-top: 19px;
        }
    }
    span {
        font-size: 12px;
        font-weight: 600;
        color: #333333;
        margin: 0 12px 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
            }
        }
        .right {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
    }
    .search-two {
        display: flex;
        p {
            padding-bottom: 10px;
        }
    }
}
.table-title {
    display: flex;
    justify-content: space-between;
    height: 248px;
    margin-bottom: 12px;
    .table_title_left {
        margin-right: 12px;
        border-radius: 4px;
        width: 675px;

        .table_title_left_box {
            overflow: hidden;
            display: flex;
            align-items: center;
            > div {
                width: 160px;
                height: 140px;
                background: #b9a1f9;
                border-radius: 12px;
                box-sizing: border-box;
                padding-top: 18px;
                padding-left: 16px;
                > i {
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    color: #fff;
                    border-radius: 50%;
                    margin-right: 12px;
                }
                > div {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 14px;
                        color: #39324e;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    p {
                        color: #39324e;
                        font-size: 16px;
                        font-style: normal;
                        margin-bottom: 0;
                    }
                }
            }
            > div:nth-child(1) {
                i {
                    color: #404040;
                    font-size: 32px;
                    width: 32px;
                    height: 32px;
                    margin-bottom: 21px;
                }
            }
            > div:nth-child(2) {
                background: #fad376;
                i {
                    color: #ffbc42;
                    font-size: 32px;
                    width: 32px;
                    height: 32px;
                    margin-bottom: 21px;
                }
                span {
                    color: #132a57;
                }
                p {
                    color: #132a57;
                }
            }
            > div + div {
                margin-left: 20px;
            }
            .table_title_left_item2 {
                display: flex;
                width: 352px;
                height: 140px;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: space-between;
                padding: 0;
                background-color: transparent;
                .yibaoxiao {
                    position: absolute;
                    right: -55px;
                    top: 2;
                    width: 52px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    font-style: normal;
                    line-height: 16px;
                    height: 16px;
                    background: #ff6600;
                    border-radius: 3px;
                }
                > div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    i {
                        width: 54px;
                        height: 54px;
                        background: #783efd;
                        border-radius: 12px;
                        display: block;
                        margin-right: 12px;
                        text-align: center;
                        line-height: 54px;
                        font-size: 26px;
                        color: #fff;
                    }
                    span {
                        margin-bottom: 16px;
                        display: block;
                        position: relative;
                    }
                }
                > div:nth-child(2) {
                    i {
                        background-color: #ffcd41;
                        font-size: 32px;
                    }
                }
                > div:nth-child(3) {
                    i {
                        background-color: #4fdfb1;
                        font-size: 22px;
                    }
                }
                > div:nth-child(4) {
                    i {
                        background-color: #fd8f65;
                        font-size: 32px;
                        line-height: 49px;
                    }
                }
            }
        }
    }
    .table_title_right {
        width: calc(100% - 743px);
        position: relative;
    }
    > div {
        padding: 16px 12px 0 16px;
        background-color: #fff;
        p {
            font-size: 14px;
            font-weight: 600;
            color: #333;
            margin-bottom: 24px;
        }
    }
}
.table {
    height: 248px;
    flex: 1;
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelList {
    display: inline-block;
    .cancels {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        color: #fff;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -26px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -28px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
    p {
        position: relative;
        display: inline-block;
        padding: 2px 8px;
        padding-bottom: 2px !important;
        border-radius: 2px;
        margin-top: 3px;
        border: 1px solid #2370eb;
        margin-left: 16px;
        text-align: center;
        font-size: 14px;
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancels {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancels {
            border-left: 10px solid #fa8c15;
        }
    }
}
.product_box {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    div {
        margin-right: 20px;
    }
    p {
        width: 170px;
        height: 180px;
        border-radius: 12px;
        margin-bottom: 12px !important;
        box-sizing: border-box;
        padding: 20px 0 25px 16px;
        display: flex;
        flex-direction: column;

        position: relative;
        i {
            position: absolute;
            right: 13px;
            top: 20px;
            color: #fff;
            font-weight: 500;
            display: block;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 21px;
            border-radius: 50%;
            font-size: 13px;
        }

        span {
            font-size: 14px;
            color: #132a57;
        }
        span:nth-child(2) {
            font-size: 18px;
            margin-top: 20px;
        }
        em {
            font-style: normal;
            margin-top: 6px;
            display: block;
        }
    }

    > div:nth-child(1) {
        p {
            background: #ecf6ff;
            i {
                background-color: #1b51f6;
            }
        }
    }
    > div:nth-child(2) {
        p {
            background: #f7f4ff;
            i {
                background-color: #381bf6;
            }
        }
    }
    > div:nth-child(3) {
        p {
            background: #ffebe6;
            i {
                background-color: #f61b5d;
            }
        }
    }
    > div:nth-child(4) {
        p {
            background: #fdf4da;
            i {
                background-color: #f69b1b;
            }
        }
    }
    > div:nth-child(5) {
        p {
            background: #ecf6ff;
            i {
                background-color: #1b51f6;
            }
        }
    }
    .icon-xiangzuo2 {
        transform: rotate(180deg);
        font-size: 30px;
        margin-top: -8px;
        color: #1b51f6;
    }
    .icon-yumingfuwu {
        font-size: 18px;
    }
}
/deep/ .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    background: #3c3c3c;
    border-radius: 3px;
}
/deep/ .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 8px;
}
/deep/ .swiper-pagination-bullet-active {
    background: #1b51f6;
}
.table_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 12px 12px 12px 15px;
    .add {
        border-color: #d9d9d9;
        color: #333333;
    }
    p {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        span {
            width: 160px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #1b51f6;
            display: inline-block;
            text-align: center;
            line-height: 32px;
            color: #1b51f6;
        }
        span + span {
            margin-left: 12px;
        }
        span:nth-child(2) {
            color: #381bf6;
            border-color: #381bf6;
        }
        span:nth-child(2) {
            color: #d6144f;
            border-color: #d6144f;
        }
        // .active {
        //     background: #e9f2ff;
        //     color: #2370eb;
        //     position: relative;
        // }
        // .active:after {
        //     content: '';
        //     position: absolute;
        //     bottom: -13px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: 32px;
        //     height: 2px;
        //     background: #2370eb;
        // }
    }
    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
        background: #fff;
        color: #333;
        border-color: #d9d9d9;
    }

    button:hover {
        background: #2370eb;
        color: #ffffff;
        border-color: #2370eb;
    }
    .cancel:hover {
        background: #fff;
        color: #333;
        border-color: #d9d9d9;
    }
}
.shangwu {
    cursor: pointer;
    span {
        width: 89px;
        height: 21px;
        border-radius: 4px;
        border: 1px solid #2370eb;
        color: #2370eb !important;
        text-align: center;
    }
    p {
        color: #2370eb !important;
    }
}







.table-title_all {
    display: flex;
    justify-content: space-between;
    height: 248px;
    margin-bottom: 12px;
    .table_title_left {
        margin-right: 12px;
        border-radius: 4px;
        width: 100%;

        .table_title_left_box {
            overflow: hidden;
            display: flex;
            padding-bottom: 18px;
            align-items: center;
            > div {
                width: 145px;
                height: 140px;
                background: #b9a1f9;
                border-radius: 12px;
                box-sizing: border-box;
                padding-top: 18px;
                padding-left: 16px;
                > i {
                    display: block;
                    text-align: center;
                    font-size: 20px;
                    color: #fff;
                    border-radius: 50%;
                    margin-right: 12px;
                }
                > div {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 14px;
                        color: #39324e;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    p {
                        color: #39324e;
                        font-size: 16px;
                        font-style: normal;
                        margin-bottom: 0;
                    }
                }
            }
            > div:nth-child(1) {
                i {
                    color: #404040;
                    font-size: 32px;
                    width: 32px;
                    height: 32px;
                    margin-bottom: 21px;
                }
            }
            > div:nth-child(2) {
                background: #fad376;
                i {
                    color: #ffbc42;
                    font-size: 32px;
                    width: 32px;
                    height: 32px;
                    margin-bottom: 21px;
                }
                span {
                    color: #132a57;
                }
                p {
                    color: #132a57;
                }
            }
            > div + div {
                margin-left: 20px;
            }
            .table_title_left_item2 {
                display: flex;
                width: 382px;
                height: 140px;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: space-between;
                padding: 0;
                background-color: transparent;
                .yibaoxiao {
                    position: absolute;
                    right: -55px;
                    top: 2;
                    width: 52px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    font-style: normal;
                    line-height: 16px;
                    height: 16px;
                    background: #ff6600;
                    border-radius: 3px;
                }
                > div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    i {
                        width: 54px;
                        height: 54px;
                        background: #783efd;
                        border-radius: 12px;
                        display: block;
                        margin-right: 12px;
                        text-align: center;
                        line-height: 54px;
                        font-size: 26px;
                        color: #fff;
                    }
                    span {
                        margin-bottom: 16px;
                        display: block;
                        position: relative;
                    }
                }
                > div:nth-child(2) {
                    i {
                        background-color: #ffcd41;
                        font-size: 32px;
                    }
                }
                > div:nth-child(3) {
                    i {
                        background-color: #4fdfb1;
                        font-size: 22px;
                    }
                }
                > div:nth-child(4) {
                    i {
                        background-color: #fd8f65;
                        font-size: 32px;
                        line-height: 49px;
                    }
                }
            }
        }
    }
    .table_title_right {
        width: calc(100% - 743px);
        position: relative;
    }
    > div {
        padding: 16px 12px 0 16px;
        background-color: #fff;
        p {
            font-size: 14px;
            font-weight: 600;
            color: #333;
            margin-bottom: 24px;
        }
    }
}
</style>
