<template>
    <div class="table">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            key="1"
            :show-header="false"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="6"> </el-table-column>
            <el-table-column>
                <template slot-scope="{ row, $index }">
                    <div class="progress_box">
                        <em
                            :style="{
                                background: row.color,
                            }"
                        >
                            {{ $index + 1 }}
                        </em>
                        <i v-if="btnP.userSearch || btnP.depSearch">{{
                            !btnP.depSearch && btnP.userSearch
                                ? row.adminName
                                : pageData.param.departmentId
                                ? row.adminName
                                : row.departmentName
                        }}</i>
                        <div class="total_width">
                            <div
                                class="select_width"
                                :style="{
                                    width:
                                        row.contractAmount > 0
                                            ? (
                                                  (row.contractReturnAmount /
                                                      row.contractAmount) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? 100 + '%'
                                                : (
                                                      (row.contractReturnAmount /
                                                          row.contractAmount) *
                                                      100
                                                  ).toFixed(2) + '%'
                                            : 0,
                                }"
                            >
                                <span class="progress">
                                    {{ row.contractReturnAmount || 0 }}
                                </span>
                            </div>
                        </div>
                        <span>{{ row.contractAmount || 0 }}</span>
                        <el-button
                            type="primary"
                            @click="toAchievement(row)"
                            style="display: block"
                        >
                            查看详情
                        </el-button>
                    </div>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <a
                    style="display: block; color: blue; font-size: 14px；text-decoration: underline; cursor: pointer"
                    type="primary"
                    @click="toAchievement(-1)"
                >
                    查看详情
                </a>
            </div>
        </el-table>
    </div>
</template>
<script>
import { performanceBriefList } from '@/api/performance/performance.js';
export default {
    components: {},
    data() {
        return {
            tableData: [],
            btnP: {},
            drawerDealings: false,
            drawerDetails: false,
            direction: 'rtl',
            drawerAddClue: false,
            drawerTitle: '',
            pageData: {},
        };
    },
    methods: {
        getData(searchVal, btnP) {
            this.btnP = btnP;
            this.pageData = JSON.parse(JSON.stringify(searchVal));
            console.log(searchVal);

            if (searchVal.param.adminId) {
                delete searchVal.param.departmentId;
            }

            performanceBriefList(searchVal).then((res) => {
                this.tableData = res.data.list;
                if (this.tableData[0] == null) {
                    this.tableData = [];
                } else {
                    res.data.list.forEach((item, index) => {
                        switch (index % 5) {
                            case 0:
                                item.color = '#FCE5A3';
                                break;
                            case 1:
                                item.color = '#E6F8FF';
                                break;
                            case 2:
                                item.color = '#FCC1A3';
                                break;
                            case 3:
                                item.color = '#A3FCBB';
                                break;
                            case 4:
                                item.color = '#C6FCA3';
                                break;
                        }
                    });
                }
                this.$emit('totalNub', res.data.total);
            });
        },
        // 置顶
        onTopping(row) {
            let data = {
                param: {
                    id: row.customerAdminId,
                },
            };
            if (row.topStatus == 0) {
                data.param.topStatus = 1;
            } else {
                data.param.topStatus = 0;
            }
            customerTop(data).then((res) => {
                if (row.topStatus == 0) {
                    if (res.code == 200) {
                        this.$message.success('标记成功');
                    }
                } else {
                    if (res.code == 200) {
                        this.$message.success('取消成功');
                    }
                }

                this.$emit('getData');
            });
        },
        toAchievement(row) {
            if (row == -1) {
                this.$router.push({
                    path: '/myachievementlistdetails',
                    query: {
                        adminId: sessionStorage.getItem('adminId'),
                    },
                });
            } else {
                if (!this.btnP.depSearch) {
                    this.$router.push({
                        path: '/myachievementlistdetails',
                        query: {
                            adminId: row.adminId,
                            month: this.pageData.param.createMonth,
                        },
                    });
                } else {
                    if (this.pageData.param.departmentId) {
                        this.$router.push({
                            path: '/myachievementlistdetails',
                            query: {
                                adminId: row.adminId,
                                departmentId: row.departmentId,
                                month: this.pageData.param.createMonth,
                            },
                        });
                    } else {
                        this.$router.push({
                            path: '/myachievementlistdetails',
                            query: {
                                departmentId: row.departmentId,
                                month: this.pageData.param.createMonth,
                            },
                        });
                    }
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table {
    width: 100%;
    height: 100%;
}
/deep/ td:nth-child(4) {
    padding: 8px 0 !important;
}
.blue {
    color: #2370eb;
    font-size: 12px;
}
.yellow {
    color: #ff703b;
    font-size: 12px;
}
.icon-shoucang {
    color: #feb516;
}
.icon-star {
    color: #ccc;
}
.Times {
    height: 24px;
    background: #fff2ed;
    border-radius: 2px;
    border: 1px solid #ff7a44;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7a45;
}
.customer_name {
    display: flex;
    align-items: center;
    > div {
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            span + span {
                margin-left: 7px;
            }
        }
    }
}
/deep/ .el-table__row td {
    font-size: 12px !important;
}
.companyDomain {
    font-size: 12px;
    p + p {
        margin-top: 10px;
    }
}
.icon-dianhua {
    color: #2370eb;
}
.icon-weixin {
    color: #50b674;
}
.icon-QQ {
    color: #2370eb;
}
.icon-youxiang {
    color: #feb516;
}
.icon-qita {
    color: #13ab16;
}
.icon-yuyuebaifang {
    color: #f15641;
}
.icon-qita1 {
    color: #af90ff;
}
.icon-zuoji {
    color: #6a58ca;
}
.icon-duanxinxiaoxixinxi {
    color: #13ab16;
}
.icon-diannao {
    color: #87b1e9;
}
.company_contact {
    .icon-dianhua,
    .icon-weixin,
    .icon-QQ,
    .icon-youxiang,
    .icon-qita,
    .icon-youxiang1,
    .icon-yuyuebaifang,
    .icon-qita1,
    .icon-diannao,
    .icon-duanxinxiaoxixinxi,
    .icon-zuoji {
        position: static;
        left: 0;
        top: 0;
    }
    p {
        span {
            display: inline-block;
            margin: 0 8px;
            width: 50px;
        }
    }
}
.icon-renzheng {
    position: absolute;
    left: -10px;
    top: 49%;
    transform: translateY(-50%);
    color: #f5a623;
}
.icon-shijian01 {
    position: absolute;
    left: 4px;
    top: 51%;
    transform: translateY(-50%);
    color: #bc70ff;
}
.deal_summary {
    span {
        cursor: pointer;
        color: #2370eb;
    }
}
.deal_summary_popover {
    font-size: 12px;
    span {
        display: inline-block;
        cursor: pointer;
        user-select: none;
    }
}
.circle_toast {
    font-size: 12px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #f7b84f;
    color: #fff;
    line-height: 12px;
    border-radius: 50%;
    text-align: center;
    font-style: normal;
    position: absolute;
    right: 0;
    top: 49%;
    transform: translateY(-50%);
}
.tag_list {
    span + span {
        margin-left: 10px;
    }
}
/deep/ .el-progress-bar__innerText {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 13px;
    background: #1b51f6;
    border: 1px solid #1b51f6;
    border-radius: 4px;
}
.progress_box {
    width: 100%;
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;
    > em {
        width: 30px;
        font-style: normal;
        height: 30px;
        display: inline-block;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
    }
    > i {
        font-style: normal;
        margin-left: 12px;
        font-size: 14px;
        width: 60px;
    }
    .total_width {
        width: calc(100% - 157px - 185px);
        height: 9px;
        margin-left: 50px;
        background: #f0f0f0;
        border-radius: 5px;
        position: relative;
        top: 12px;
        z-index: 1;
        .select_width {
            width: 0;
            background: #1b51f6;
            border-radius: 5px;
            height: 9px;
            position: absolute;
            left: 0;
            top: 0%;
            z-index: 2;
            .progress {
                position: absolute;
                right: -27px;
                top: -25px;
                color: #fff;
                display: inline-block;
                width: 60px;
                line-height: 1.3;
                text-align: center;
                background-color: #1b51f6;
                border: 1px solid #1b51f6;
                border-radius: 4px;
                z-index: 3;
            }
            .progress:after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -9px;
                width: 0px;
                height: 0px;
                border: 4px solid #000;
                border-top-color: #1b51f6;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-right-color: transparent;
            }
        }
    }
    button {
        width: 88px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        color: #2370eb;
        font-size: 12px;
    }
    > span {
        // position: absolute;
        // top: 48%;
        // right: 250px;
        display: block;
        width: 64px;
        margin-top: 23px;
        margin-left: 12px;
        color: #333;
    }
}
</style>
